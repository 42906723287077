import menu from "./sections/Menu";
import Footer from "./sections/Footer";
import OfficeTour from "./sections/OfficeTour";
import Benefits from "./sections/Benefits";
import Relocate from "./sections/Relocate";
import Openings from "./sections/Openings";
import video from "../images/dynamic-header/join-us.mp4";
import poster from "../images/dynamic-header/join-us.jpg";

const data = {
  meta: {
    title: "Join us at Socialpoint",
    description:
      "Join us and become part of the Socialpoint family. You have the talent, we have the playground.",
    keywords:
      "socialpoint, social point, mobile games, free to play, f2p, free 2 play, dragon city, monster legends, word life, social games, top Facebook games, mobile games developer"
  },
  menu: menu(4),
  components: [
    {
      name: "Wrapper",
      props: {
        backgroundColor: "gray"
      },
      components: [
        {
          name: "SectionHeader",
          props: {
            image:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/join_us/join_us_header.jpg",
            title: "You have the talent,\n we have the playground.",
            imageMobile: {
              url:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/join_us/join_us_header_portrait.jpg"
            },
            video: {
              src: video,
              poster: poster,
              //"//sp-tools-website.s3-eu-west-1.amazonaws.com/media/join_us/join_us_header.jpg",
              player: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
                alt: ""
              },
              fullWidth: true,
              videoAsHeader: true
            }
          }
        },
        Openings,
        {
          name: "Separator",
          props: {
            topColor: "gray",
            bottomColor: "gray"
          }
        },
        // ---- hiding job openings section caraousel design ----
        // {
        //   name: "CurrentOpenPositions",
        //   props: {
        //     title: "Openings",
        //     itemsTitle: "Latest Vacancies",
        //     cta: {
        //       title: "View all openings",
        //       hrefTo: "/join-us/careers",
        //       icon: {
        //         src:
        //           "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
        //         alt: ""
        //       }
        //     },

        //     items: [
        //       // {
        //       //   id: '1',
        //       //   image: {
        //       //     src: '//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/offer_hole.png',
        //       //     alt: '',
        //       //   },
        //       //   title: 'Creative Marketing Director (Gaming)',
        //       //   cta: {
        //       //     title: 'Read more',
        //       //     icon: {
        //       //       src: '//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg',
        //       //       alt: '',
        //       //     },
        //       //     hrefTo: '',
        //       //   },
        //       // },
        //     ],
        //     dragInstructions: {
        //       text: "Drag to view more",
        //       icon: {
        //         src:
        //           "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
        //         alt: ""
        //       }
        //     }
        //   }
        // },
        {
          name: "WhySocialpoint",
          props: {
            top: {
              title: "Why Socialpoint?",
              htmlText: `<p>We’re an international gaming company oﬀering a fun, fresh,
                            and lively workplace in beautiful Barcelona.
                          </p>
                          <p>
                          We’re building the best team ever, full of talented people, who think big and want to make a diﬀerence in the mobile gaming market. Sound familiar? Then we want you on board.
                          </p>
                          <p>Are you ready for a new adventure? Press start.</p>`,
              cta: {
                title: "Explore by Job Function",
                hrefTo: "/join-us/careers"
              },
              image: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/join_us/join_us_1.png",
                alt: ""
              }
            },
            middle: {
              image1: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/join-us/why-socialpoint/image_1.jpg",
                alt: ""
              },
              image2: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/join-us/why-socialpoint/image_2.jpg",
                alt: ""
              },
              image3: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/join-us/why-socialpoint/image_3.jpg",
                alt: ""
              }
            },
            bottom: {
              title: "What's in it for you?",
              htmlText:
                "<p>At Socialpoint, you’ll be challenged to reach your full potential, in a place where passion, innovation and transparency are encouraged and rewarded.</p><p> Between us, there are over 30 diﬀerent nationalities here, so you’ll have no problem ﬁtting in. Whether it’s beach volleyball, board games or pool parties, there’s plenty of fun to be had.</p><p> Working with us will develop your skills and build your career. What are you waiting for?</p>",
              cta: {
                title: "Check out our benefits",
                icon: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
                  alt: ""
                },
                hrefTo: "/join-us/benefits"
              },
              image: {
                src:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/join_us/join_us_5.png",
                alt: ""
              }
            }
          }
        },
        {
          name: "Video",
          props: {
            src: "https://vimeo.com/1064217398",
            poster:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/video-poster.jpg",
            player: {
              src:
                "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
              backgroundColor: "purple",
              alt: "",
              color: "white"
            }
          }
        },
        {
          name: "Separator",
          props: {
            topColor: "gray",
            bottomColor: "green"
          }
        },
        {
          name: "Wrapper",
          props: {
            backgroundColor: "green"
          },
          components: [
            {
              name: "ApplicationProcess",
              props: {
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/join_us/join_us_6.png",
                  alt: ""
                },
                title: "Application process",
                htmlText:
                  "At Socialpoint, we know that talent is our most important asset, so we do everything we can to ensure any potential hire is a perfect ﬁt. Our recruitment process is designed to give you a great candidate experience and a clear understanding of what it would be like to work here.",
                cta: {
                  title: "View all openings",
                  hrefTo: "/join-us/careers"
                },
                dragInstructions: {
                  text: "Drag to view more",
                  icon: {
                    src:
                      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
                    alt: ""
                  }
                },
                steps: [
                  {
                    title: "Step 1. Application",
                    text: `Complete the application
                      form and send us your CV
                      with any supporting info.`
                  },
                  {
                    title: "Step 2. Initial call",
                    text: `Usually a short call
                      with our recruitment team.
                      This is a great opportunity
                      to ask us questions.`
                  },
                  {
                    title: "Step 3.\nTest",
                    text: `Look at this stage as a chance
                      to showcase your skills.`
                  },
                  {
                    title: "Step 4. Interview",
                    text: `Usually a video call, this
                      is a more technical/functional
                      conversation than the
                      initial interview.`
                  },
                  {
                    title: "Step 5. Final Interview",
                    text: `Congratulations,
                            you’ve made it this
                            far!
                            You’ll meet your
                            future team and
                            finalize the process.`
                  }
                ]
              }
            }
          ]
        },
        {
          name: "Separator",
          props: {
            topColor: "green",
            bottomColor: "purple"
          }
        },
        {
          name: "Wrapper",
          props: {
            backgroundColor: "purple"
          },
          components: [Benefits]
        },
        {
          name: "Separator",
          props: {
            topColor: "purple",
            bottomColor: "purple-light"
          }
        },
        {
          name: "Wrapper",
          props: {
            backgroundColor: "purple-light"
          },
          components: [
            Relocate,
            {
              name: "Video",
              props: {
                src: "https://vimeo.com/980388448",
                poster:
                  "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/new-office/join-us/office-tour-thumbnail.jpg",
                player: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/player-play.svg",
                  backgroundColor: "purple",
                  alt: "",
                  color: "white"
                }
              }
            },
            {
              name: "Separator",
              props: {
                topColor: "purple-light",
                bottomColor: "purple-light"
              }
            }
          ]
        },
        {
          name: "FullWidthImage",
          props: {
            src:
              "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/join_us/join_us_large.jpg",
            alt: "Team"
          }
        },
        /* 
            ---- Hiding "Meet the team" section temporarily ---- 
        {
          name: "Wrapper",
          props: {
            backgroundColor: "blue-light"
          },
          components: [
            {
              name: "MeetTheTeam",
              props: {
                title: "Meet the team",
                htmlText:
                  "<p>Our big family is always excited to welcome new members from all around the globe. Socialpointers come from over 30 different countries, providing an international perspective on the world of social gaming. Where are you from?</p>",
                cta: {
                  title: "Know more",
                  hrefTo: "/join-us/team"
                },
                items: [
                  {
                    id: "1",
                    name: "Gillian Patterson",
                    position: "Mobile Game Developer",
                    image:
                      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/team/gillian.jpg",
                    cta: {
                      title: "Read more",
                      icon: {
                        src:
                          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
                        alt: ""
                      },
                      hrefTo: "/join-us/team"
                    }
                  },
                  {
                    id: "2",
                    name: "Pasqual Batalla",
                    position: "Chief Operations Officer",
                    image:
                      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/team/pasqual.jpg",
                    cta: {
                      title: "Read more",
                      icon: {
                        src:
                          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
                        alt: ""
                      },
                      hrefTo: "/join-us/team"
                    }
                  },
                  {
                    id: "3",
                    name: "Yemin (Rae) Sun ",
                    position: "UI/UX Designer",
                    image:
                      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/team/yemin.jpg",
                    cta: {
                      title: "Read more",
                      icon: {
                        src:
                          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
                        alt: ""
                      },
                      hrefTo: "/join-us/team"
                    }
                  },
                  {
                    id: "4",
                    name: "Arnau Escapa",
                    position: "Data Scientist",
                    image:
                      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/team/arnau.jpg",
                    cta: {
                      title: "Read more",
                      icon: {
                        src:
                          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
                        alt: ""
                      },
                      hrefTo: "/join-us/team"
                    }
                  },
                  {
                    id: "5",
                    name: "Garazi Zarraga",
                    position: "Relocation Coordinator",
                    image:
                      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/team/garazi.jpg",
                    cta: {
                      title: "Read more",
                      icon: {
                        src:
                          "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/arrows-right.svg",
                        alt: ""
                      },
                      hrefTo: "/join-us/team"
                    }
                  }
                ],
                image: {
                  src:
                    "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/join_us/join_us_7.png",
                  alt: ""
                },
                dragInstructions: {
                  text: "Drag to view more",
                  icon: {
                    src:
                      "//sp-tools-website.s3-eu-west-1.amazonaws.com/media/static/gallery-right-arrow.svg",
                    alt: ""
                  }
                }
              }
            }
          ]
        },
        {
          name: "Separator",
          props: {
            topColor: "blue-light",
            bottomColor: "gray"
          }
        },
        */
        // {
        //   name: "Wrapper",
        //   props: {
        //     backgroundColor: "blue-light"
        //   },
        //   components: [OfficeTour]
        // },
        Footer
      ]
    }
  ]
};

export default data;
